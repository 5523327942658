<template>
    <div class="container-pc">
        <div class="common-view Global-W">
            <div class="common-title f-w">Profile Page</div>
            <div class="common-box">
                <div class="common-item" v-for="(item, index) in commonList" :key="index">
                    <a :class="[route.name == item.name ? 'select-tab' : '', 'HOVERVIEW HOVER HOVER-C']"
                        @click="handleChange(item)">
                        {{ item.title }}
                    </a>
                </div>
            </div>
        </div>
        
    </div>
    <div class="container-m">
        <div class="common-view-m">
            <div class="common-title-m">Profile Page</div>
            <div class="common-box-m">
                <div class="common-item-m" v-for="(item, index) in commonList" :key="index">
                    <a :class="[route.name == item.name ? 'select-tab' : '', 'HOVERVIEW HOVER HOVER-C']"
                        @click="handleChange(item)">
                        {{ item.title }}
                    </a>
                </div>
            </div>
        </div>
        <!-- <router-view></router-view> -->
    </div>
    <router-view></router-view>
</template>



<script setup name="Common">
import { ref, } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute()
const router = useRouter()


const commonList = ref([{
    title: "My Order",
    name: 'OrderList',
    path: '/common/orders'
},
{
    title: "Personal Information",
    name: 'MyInfo',
    path: '/common/myinfo'
}, {
    title: "Shipping Address",
    name: 'Address',
    path: '/common/address'
}])


const handleChange = (item) => {
    router.push({
        path: item.path
    })
}

</script>


<style lang="scss" scoped>
.common-view {
    margin: 40px auto 20px;
}

.common-title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    text-align: center;
    color: #414141;
}

.common-box {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 0 30px;
    background-color: #f1f1f1;

    .common-item {
        // width: 100px;
        height: 100%;
        line-height: 50px;
        text-align: center;
        padding: 0 20px;
        
        .select-tab:after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: #414141;
        }
    }
}


.container-m {
    .common-view-m {
        padding: 0 .3rem;

        .common-title-m {
            width: 100%;
            height: .5rem;
            line-height: .5rem;
            text-align: center;
            font-size: .3rem;
        }

        .common-box-m {
            display: flex;
            align-items: center;
            width: 100%;
            height: .8rem;
            font-size: .2rem;
            padding: 0 .3rem;
            background-color: #f1f1f1;
            margin: .2rem 0;

            .common-item-m {
                display: flex;
                align-items: center;
                height: 100%;
                margin-right: .2rem;

                .select-tab:after {
                    content: "";
                    position: absolute;
                    bottom: -10px;
                    left: 0;
                    right: 0;
                    height: 2px;
                    background-color: #414141;
                }
            }
        }
    }
}
</style>